<template>
  <div
    v-if="model.fileName && (model.categoryId != 1 || (model.categoryId == 1 && (!model.title || !model.title.length)))"
    class="page-background">
    <img :src="model.fileName" @error="error" />
    <img :src="model.fileName" class="bg-blur" />
    <div class="content" v-html="model.description"></div>
  </div>
  <div v-else-if="model.categoryId == 1" class="main-template-div page-background">
    <img class="main-image" @load="loaded" :src="model.fileName" @error="error" alt="" />
    <img :src="model.fileName" class="bg-blur" />
    <div v-if="model.title && model.title.length" v-show="className" :class='className'>
      <p :style="{ 'font-family': fontType }">{{ model.title }}</p>
    </div>
    <div v-else class="content" v-html="model.description"></div>
  </div>
</template>

<script>
import store from '../store/index';
import contentManager from '../manager/modules/content-manager';
import $ from "jquery";
export default {
  name: 'background-image',
  props: ['data'],
  created() {
    this.startTimer();
  },
  mounted() {
  },
  data() {
    return {
      destroyed: false,
      className: null,
      fontType: 'LuckiestGuyRegular'
    };
  },
  methods: {
    loaded() {
      if (this.model.categoryId == 1) {
        this.fontType = 'LuckiestGuyRegular';
        if (this.model.properties && this.model.properties.length) {
          if (!localStorage.getItem('imagePositionTypes') && navigator.onLine) {
            contentManager.downloadImageFontsAndPositionsAndBackgrounds().then(() => {
              this.setImageSettings();
            });
          }
          else {
            this.setImageSettings();
          }
        }
      }

    },
    setImageSettings() {
      var imagePosition = 'top-slide';
      var imageColor = 'gold-image ';
      var imagePositionTypes = localStorage.getItem('imagePositionTypes');
      let fontTypes = localStorage.getItem('fontTypes')
      let backgroundTypes = localStorage.getItem('backgroundTypes')
      var item = null, list = null, val = null;
      var fontTypeProp = this.model.properties.filter(f => f.id == 17);
      if (fontTypeProp && fontTypeProp.length) {
        val = fontTypeProp[0].defaultValue;
        if (val && fontTypes && fontTypes.length) {
          list = JSON.parse(fontTypes);
          if (list && list.length) {
            item = list.filter(f => f.id == val);
            if (item && item.length) {
              this.fontType = item[0].title;
            }
          }
        }
      }
      item = null;
      list = null;
      val = null;
      var imagePositionProp = this.model.properties.filter(f => f.id == 19);
      if (imagePositionProp && imagePositionProp.length) {
        val = imagePositionProp[0].defaultValue;
        list = JSON.parse(imagePositionTypes);
        if (list && list.length) {
          item = list.filter(f => f.id == val);
          if (item && item.length) {
            imagePosition = item[0].title;
          }
        }
      }
      item = null;
      list = null;
      val = null;
      var imageColorProp = this.model.properties.filter(f => f.id == 18);
      if (imageColorProp && imageColorProp.length) {
        val = imageColorProp[0].defaultValue;
        list = JSON.parse(backgroundTypes);
        if (list && list.length) {
          item = list.filter(f => f.id == val);
          if (item && item.length) {
            imageColor = item[0].title;
          }
        }
      }

      this.className = 'template-title-div ' + imagePosition + ' ' + imageColor + ' ' + this.fontType;
      $('.main-template-div .main-image').animate({ 'right': "0" }, 400, () => {
        // var cnt = this.model.title.length;
        // if (cnt) {
        //   $('.main-template-div .top-slide').css('width', (cnt * 3.25) + '%');
        // }
        $('.main-template-div .left-slide').animate({
          'left': '0'
        }, 500, () => {
        })
        $('.main-template-div .right-slide').animate({
          'right': '-25px'
        }, 500, () => {
        })
        $('.main-template-div .top-slide').animate({
          'top': '50px'
        }, 500, () => {
        })
      })
    },
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch('setNextPlaylist');

      }, this.model.displaySecond * 1000);
    },
    error() {
      store.dispatch("setNextPlaylist");
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
.page-background {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    z-index: 2;
    position: absolute;
  }

  img.bg-blur {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
    -webkit-filter: blur(16px);
    /* Safari 6.0 - 9.0 */
    filter: blur(16px);
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 12px 36px;
    position: absolute;
    top: 0;
    color: #f1f1f1;
    font-family: Montserrat-Bold, tahoma;
    z-index: 6;
    font-size: 76px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  }
}

@font-face {
  font-family: 'Chalkduster';
  src: url('../assets/fonts/template-fonts/Chalkduster.woff');
}

@font-face {
  font-family: LuckiestGuyRegular;
  src: url(../assets/fonts/template-fonts/LuckiestGuy-Regular.ttf);
}

@font-face {
  font-family: PhosphateInlineRegular;
  src: url(../assets/fonts/template-fonts/Phosphate-Inline-Regular.otf);
}

@font-face {
  font-family: PhosphateSolidRegular;
  src: url(../assets/fonts/template-fonts/Phosphate-Solid-Regular.otf);
}

@font-face {
  font-family: PhosphateProInline;
  src: url(../assets/fonts/template-fonts/PhosphatePro-Inline.otf);
}

@font-face {
  font-family: PhosphateSolid;
  src: url(../assets/fonts/template-fonts/PhosphatePro-Solid.otf);
}

@font-face {
  font-family: ManisBanget;
  src: url('../assets/fonts/template-fonts/Manis Banget.otf');
}


.Chalkduster {
  -webkit-text-stroke: 0px #ffa923;
}

.LuckiestGuyRegular {
  -webkit-text-stroke: 7px #535151;
}

.PhosphateInlineRegular {
  -webkit-text-stroke: 0px #ffa923;
}

.ManisBanget {
  -webkit-text-stroke: 0px #535151;
}


.main-template-div {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.main-template-div .main-image {
  width: 100vw;
  height: 100vh;
  right: -2000px;
  position: absolute;
}

.main-template-div>div {
  background-position: center;
  background-size: 100% 100%;
  position: absolute;
  z-index: 99;
  align-items: center;
  align-content: center;
  min-width: 400px;
  max-width: 98%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-slide {
  left: 50%;
  margin: auto;
  top: -4000px;
  transform: translate(-50%);
}

.left-slide {
  left: -4000px;
  top: 50px;
}

.right-slide {
  right: -4000px;
  top: 50px;
}

.main-template-div .green-image {
  background: url('/images/image-template/green.png') center/100% 100% no-repeat !important;
  paint-order: stroke fill;
}

.main-template-div .red-image {
  background: url('/images/image-template/red.png') center/100% 100% no-repeat !important;
  paint-order: stroke fill;
}

.main-template-div .gold-image {
  background: url('/images/image-template/gold.png') center/100% 100% no-repeat !important;
  paint-order: stroke fill;
}

/* #ffa923 */
svg {
  font-size: 30px;
  font-weight: bold;
  height: 100%;
  fill: white;
}

.svgText {
  fill: white;
  stroke: black;
  stroke-width: 15px;
  stroke-linejoin: round;
  paint-order: stroke;
}

.main-template-div>div>p {
  font-size: 85px;
  text-align: center;
  padding: 0px 50px;
  word-spacing: 15px;
  color: white;
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
}
</style>
